import React from 'react';
import { NavLink } from 'react-router-dom';
import './Home.css';
import Services from '../../components/services/services';
import Products from "../Products/Products";
import Features from '../Features/Features';
import Resources from '../Resources/Resources';
import Aboute from '../Aboute/Aboute';
import langAM from '../../Translations/lang_am.json';
import langEN from '../../Translations/lang_en.json';
import OurPartners from '../OurPartners/OurPartners';
import CompanyTeam from '../CompanyTeam/CompanyTeam';
import FAQ from '../FAQ/FAQ';
import HeroSlideCanvas from "../../components/HeroSlideCanvas/HeroSlideCanvas";

function Home({ currentLanguage, handleLanguageSwitch }) {
  const translations = currentLanguage === 'en' ? langAM : langEN;

  return (
    <div>
      <section className="landing-section" id="top">
        {/* <HeroSlideCanvas/> */}
        <div className="landing-content">
          <h1>{translations.welcome_message}</h1>

          <p>At <b>CodeRed</b>, we are your trusted partner for cutting-edge <b>Blockchain </b>and <b>Software development</b>. Our expertise spans a wide range of services, including <b>Custom Software Development, dApps, DeFi platforms, Smart Contracts, Mobile App Development, and AI</b> -powered solutions.</p>
          <p>{translations.intro_paragraph2}</p>
          <NavLink to="get-in-touch" smooth="true" duration={505}>
            <button className="cta-button">{translations.get_started_button}</button>
          </NavLink>
        </div>

        {/* Three.js Scene Component within the landing section */}
        <div className="three-scene-wrapper">
     
        </div>
      </section>

      {/* Other Sections */}
      <section id="products">
        <Products currentLanguage={currentLanguage} onLanguageSwitch={handleLanguageSwitch} />
      </section>
      <section id="about">
        <Aboute />
      </section>
      <section id="resources">
        <Resources />
      </section>
      <section id="features">
        <Features />
      </section>
      <section id="services">
        <Services />
      </section>
      <section id="partners">
        <OurPartners />
      </section>
      <section id="getstarted">
        <div>
          <CompanyTeam />
        </div>
      </section>
      <section id="faq">
        <div>
          <FAQ />
        </div>
      </section>
    </div>
  );
}

export default Home;
